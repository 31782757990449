import { Button } from '@/components'
import Text from '@/components/Text/Text'
import { CheckIcon, DotIcon } from 'lucide-react'
import { useViewModel } from './viewModel'

export default function ReviewProgram() {
  const {
    selectedDeviceNames,
    reviewedTOS,
    selectedUtility,
    onEnroll,
    eligible,
    totalAwardedDollars,
  } = useViewModel()
  return (
    <div className="flex flex-col justify-between h-full gap-5">
      <div className="flex flex-col gap-4">
        <section>
          <Text variant="h3">Review Program</Text>
          <Text variant="body2">
            Please review all your information before you enroll in this
            program.
          </Text>
        </section>
        <div className="flex flex-col [&>*]:py-2 [&>*]:border-t [&>*]:border-solid [&>*]:border-themed-grey-200">
          {totalAwardedDollars > 0 && (
            <section className="flex flex-col w-full">
              <RequirementHeader title="Your reward" hideCheck={true} />
              <Text variant="body1" className="text-green-900">
                ${totalAwardedDollars}
              </Text>
            </section>
          )}
          {selectedUtility && (
            <section className="flex flex-col w-full">
              <RequirementHeader title="Select an eligible utility" />
              <Text variant="body2">{selectedUtility.name}</Text>
            </section>
          )}
          {selectedDeviceNames.length > 0 && (
            <section className="flex flex-col w-full">
              <RequirementHeader title="Eligible Devices" />
              <Text variant="body2">{selectedDeviceNames.length} in Total</Text>
              {selectedDeviceNames.map((name, i) => (
                <div className="inline-flex items-center" key={i}>
                  <DotIcon className="text-themed-grey-500" />
                  <Text variant="body2">{name}</Text>
                </div>
              ))}
            </section>
          )}
          {reviewedTOS && (
            <section className="flex flex-col w-full">
              <RequirementHeader title="Terms and Conditions" />
              <Text variant="body2">Accepted</Text>
            </section>
          )}
        </div>
      </div>
      <Button id="enroll" onClick={onEnroll} disabled={!eligible}>
        Enroll in Program
      </Button>
    </div>
  )
}

function RequirementHeader({
  title,
  hideCheck,
}: {
  title: string
  hideCheck?: boolean
}) {
  return (
    <div className="flex items-center justify-between w-full">
      <Text variant="subheader">{title}</Text>
      {!hideCheck && (
        <CheckIcon
          size={18}
          className="bg-themed-success rounded-full p-0.5 text-themed-white"
        />
      )}
    </div>
  )
}
