import { useNavigation } from '@/app/hooks'
import { useProgramRequirements } from '@/app/hooks/useProgramRequirements'
import { useAppDispatch, useAppSelector, useMockableViewModel } from '@/hooks'
import Sentry from '@/logging/sentry'
import {
  chargerEnrollmentCollection,
  thermostatEnrollmentCollection,
  vehicleEnrollmentCollection,
} from '@/reducers/deviceProgramEnrollments'
import {
  completeEnrollment,
  selectInProgressEnrollment,
} from '@/reducers/inProgressUtilityProgramEnrollments'
import { programsNewCollection } from '@/reducers/programsNew'
import { thermostatsCollection } from '@/reducers/thermostats'
import { utilityProgramEnrollmentCollection } from '@/reducers/utilityProgramEnrollments'
import { searchableUtilityProgramsCollection } from '@/reducers/utilityPrograms'
import { ProfileEligibilityRequirements } from '@/types/programs-new/profile-requirements'
import { ClientRequirements } from '@/types/utilityProgramProfileEligibility'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import {
  useCalculateTotalAwardedDollars,
  getDeviceIncentives,
} from '../utils/utils'
import { UtilityProgram } from '@/types/utilityProgram'

export function useViewModel() {
  const { id: utilityProgramId } = useParams<{ id: string }>()
  const { data: utilityPrograms } =
    searchableUtilityProgramsCollection.useFetch({
      path: `utility_programs/${utilityProgramId}`,
    })
  const utilityProgram = utilityPrograms?.[0]
  const { programRequirements } = useProgramRequirements(utilityProgram)
  const eligible = programRequirements?.steps
    .filter((s) => s.key !== ClientRequirements.ProgramEnrollment)
    .every((s) => s.completed)
  const dispatch = useAppDispatch()
  const navigate = useNavigation()
  const inProgressEnrollment = useAppSelector(
    selectInProgressEnrollment(+utilityProgramId),
  )
  const utility = useAppSelector((state) => state.utilities.selectedUtility)
  const vehicles = useAppSelector((state) => state.vehicles.vehicles ?? [])
  const thermostats = useAppSelector(
    (state) => thermostatsCollection.selectors.selectAll(state) ?? [],
  )
  const chargers = useAppSelector((state) => state.chargers.chargers ?? [])
  const selectedDeviceNames = useMemo(() => {
    const hasDevicesReq = programRequirements?.steps.find(
      (s) => s.key === ClientRequirements.SelectDevice,
    )
    if (!hasDevicesReq) {
      return []
    }
    if (!inProgressEnrollment?.selectedDevices) {
      return []
    }
    const vehicleNames = vehicles
      .filter((v) =>
        inProgressEnrollment.selectedDevices.vehicleIds.includes(v.id),
      )
      .filter((v) => v.car.car_model)
      .map(
        (v) => `(${v.car.car_model?.year}) ${v.car.car_model?.friendly_name}`,
      )
    const thermostatNames = thermostats
      .filter((t) =>
        inProgressEnrollment.selectedDevices.thermostatIds.includes(t.id),
      )
      .filter((t) => t.name)
      .map((t) => t.name)
    const chargerNames = chargers
      .filter((c) =>
        inProgressEnrollment.selectedDevices.chargerIds.includes(c.id),
      )
      .filter((c) => c.name)
      .map((c) => c.name)
    return [...vehicleNames, ...thermostatNames, ...chargerNames]
  }, [programRequirements])
  const reviewedTOS = useMemo(() => {
    const hasReviewTOSReq = programRequirements?.steps.find(
      (s) => s.key === ClientRequirements.ReviewTOS,
    )
    if (!hasReviewTOSReq) {
      return false
    }
    return inProgressEnrollment?.tosAccepted ?? false
  }, [inProgressEnrollment, programRequirements])
  const selectedUtility = useMemo(() => {
    const hasSelectedUtilityRequirement = programRequirements?.steps.find(
      (s) => s.key === ProfileEligibilityRequirements.RequireUtility,
    )
    if (!hasSelectedUtilityRequirement) {
      return null
    }
    return utility
  }, [programRequirements])

  const onEnroll = async () => {
    const result = await dispatch(
      utilityProgramEnrollmentCollection.actions.create({
        utility_program_id: utilityProgram.id,
        vehicle_ids: inProgressEnrollment?.selectedDevices.vehicleIds ?? [],
        thermostat_ids:
          inProgressEnrollment?.selectedDevices.thermostatIds ?? [],
        charger_ids: inProgressEnrollment?.selectedDevices.chargerIds ?? [],
      }),
    )

    if (result.error) {
      Sentry.logMessage(
        result.payload.response?.[0] || 'Error enrolling profile',
      )
      navigate.push(
        `/multi-program-enrollment/${utilityProgramId}/enrollment-state/error`,
      )
      return
    }
    invalidateCollections()
    dispatch(completeEnrollment(utilityProgram.id))
    navigate.push(
      `/multi-program-enrollment/${utilityProgramId}/enrollment-state/pending`,
    )
  }
  const invalidateCollections = () =>
    [
      programsNewCollection,
      vehicleEnrollmentCollection,
      thermostatEnrollmentCollection,
      chargerEnrollmentCollection,
    ].forEach((collection) => dispatch(collection.actions.invalidate()))

  const deviceIncentives = getDeviceIncentives(utilityProgram as UtilityProgram)
  const totalAwardedDollars = useCalculateTotalAwardedDollars(
    inProgressEnrollment?.selectedDevices.vehicleIds ?? [],
    inProgressEnrollment?.selectedDevices.thermostatIds ?? [],
    inProgressEnrollment?.selectedDevices.chargerIds ?? [],
    deviceIncentives,
  )

  return {
    selectedDeviceNames,
    selectedUtility,
    reviewedTOS,
    onEnroll,
    eligible,
    totalAwardedDollars,
  }
}

function useMockViewModel() {
  return {}
}

export default useMockableViewModel({ useViewModel, useMockViewModel })
